
.appContent {
    flex: 1;
    padding: 4px 18px;
    margin: 0 auto;
    width: 100%;
}

.mapTooltip{
    background-color: black;
}

.textLink{
    text-decoration: underline;
    color: #1b68cb;
    cursor: pointer;
}

.configHeading{
    display: flex;
    justify-content: space-between;
    align-content: baseline;
    margin-bottom: 10px;
    padding-top: 10px;
}

.configHeading strong{
    white-space: nowrap;
}

textarea{
    border: none;
}
@media screen and (max-width: 640px) {
    .appContent {
        padding: 0;
    }

    .configHeading{
        display: flex;
        flex-direction: column;
    }
}